import React from "react"

import Template from "../components/Roadmap/Template"

const SuggestionBox: React.FC = () => {
  return (
    <Template
      basePath="suggest-feature"
      boardToken="7d7cc554-1877-ab62-cfe0-77443347b19a"
    />
  )
}

export default SuggestionBox
